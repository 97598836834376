export {
    OPT_IN_URI,
    OPT_OUT_URI,
    COOKIE_NAME,
    COOKIE_AGE,
    COOKIE_AGE_FUNCTIONAL,
    COOKIE_VALUE_ACCEPT_ALL,
    COOKIE_VALUE_FUNCTIONAL,
    cookieSetzen,
    cookieVomBackendladen,
    consentCookieAkzeptiert
}

const OPT_IN_URI = '/api/heimdall/v1/opt-in';
const OPT_OUT_URI = '/api/heimdall/v1/opt-out';
const COOKIE_NAME = 'COOKIECONSENT';
const COOKIE_VALUE_ACCEPT_ALL = 1;
const COOKIE_VALUE_FUNCTIONAL = 0;
const COOKIE_AGE = '2147483647';
const COOKIE_AGE_FUNCTIONAL = getFunctionalAge();

function cookieSetzen(name, value, maxAge) {
    return document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`;
}

function cookieVomBackendladen(uri) {
    const config = {
        credentials: 'same-origin',
        method: 'POST'
    };
    return fetch(uri, config)
        .then((response) => {
            // Response
        })
        .catch((error) => {
            // Error
        })
}

function consentCookieAkzeptiert() {
    return document.cookie
        .split(';')
        .filter((item) => item
            .includes(`${COOKIE_NAME}=${COOKIE_VALUE_ACCEPT_ALL}`)).length ?
        true :
        false;
}

function getFunctionalAge() {
    const template = document.querySelector('script#heimdall-cookie');
    if (template) {
        return template.dataset['ageFunctional'];
    }
    return undefined;
}
