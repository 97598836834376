import { COOKIE_NAME, cookieSetzen } from "../../assets/js/cookie";
const cookieAge = "2147483647"; // maximaler Wert

window.addEventListener("ucEvent", usercentricsCmpCallback);

function usercentricsCmpCallback(e) {
    const { detail } = e;
    const { event } = detail;
    if (detail && event === "consent_status") {
        const merkurOptIn = detail["Merkur"];
        const cookieWert = merkurOptIn ? 1 : 0;
        cookieSetzen(COOKIE_NAME, cookieWert, cookieAge);
    }
}
